<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col md="12">
        <b-card> 
          <div class="table-responsive">           
            <table class="table table-striped">
              <thead>
                  <tr>
                      <th>Jugador</th>
                      <th>Puntos totales</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="item,index in players">
                      <td>{{index}}</td>                 
                      <td>{{item}}</td>                 
                  </tr>                  
              </tbody>
          </table>
          </div>

        </b-card>
      
      </b-col>
    </b-row>

  </section>
</template>

<script>
import { syncRef } from '@vueuse/core';
import { BRow, BCardHeader, BCardTitle, BCardBody, BCol, BCard, BCardText, BLink, BButton, BImg, BMedia, BMediaAside, BAvatar, BMediaBody, BAlert, BTable  } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex';

export default {
  components: { BRow, BCol, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BLink, BImg, BButton, BMedia, BAvatar, BMediaAside, BMediaBody, BAlert, BTable  },
  directives: {
    Ripple,
  },
  data() {
    return {    
      players: null,     
      fields: [
        { key: 'key', label: 'Jugador/a'},
        { key: 'item', label: 'Puntos totales' },        
      ],
    }
  },
  created() {
    this.$http.get("/api/getGlobalPoints").then((response) => {
      this.players = response.data;
      this.show = false
    })
  },
  methods: {
   
  
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },
}
</script>

<style></style>
